import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Profile from './sections/Profile';
import Password from './sections/Password';
import Bottles from './sections/Bottles';
import Wants from './sections/Wants';

const MainContent = () => (
    <div className="col-md-8 account-inner-container">
        <div className="alert" />
        <Switch>
            <Route exact path="/profile" component={Profile} />
            <Route path="/profile/password" component={Password} />
            <Route path="/profile/bottles" component={Bottles} />
            <Route path="/profile/wants" component={Wants} />
        </Switch>
    </div>
);

export default MainContent;