import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import {flowRight as compose} from 'lodash';
import {UserContext} from '../../shared/UserContext';
import ProfileForm from './ProfileForm';

const UPDATE_USER = gql`
    mutation($name: String, $email: String, $avatar: String){
        updateUser(name: $name, email: $email, avatar: $avatar){
            name
            email
            avatar
        }
    }
`;


class Profile extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            isDoneSaving: false
        }
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        let currentUser = this.context;
        var self = this;

        await this.props.updateUser({ variables: currentUser}).then(() => {
            self.setState({
                isDoneSaving: true
            })
            alert("Success");
        }).catch((err) => {
            console.log(err);
        })

    }

    render() {
        return (
            <div>
                <ProfileForm submitCallback={this.handleFormSubmit} />
            </div>
        )
    }
}

Profile.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
    ]).isRequired,
    updateUser: PropTypes.func.isRequired
}

export default compose(
    graphql(UPDATE_USER,  {name: 'updateUser' })
)(Profile);