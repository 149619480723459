import React from 'react';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-client-preset';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import DocumentTitle from 'react-document-title';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { ToastProvider } from 'react-toast-notifications'

import Content from './Content';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { AUTH_TOKEN } from './utils/constant';

library.add(fal)
library.add(fad)

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_HOST });

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  const authorizationHeader = token ? `Bearer ${token}` : null;
  operation.setContext({
    headers: {
      authorization: authorizationHeader,
    },
  });
  return forward(operation);
});

const httpLinkWithAuthToken = middlewareAuthLink.concat(httpLink);

const client = new ApolloClient({
  link: httpLinkWithAuthToken,
  cache: new InMemoryCache(),
});

function App() {
  return (
<div className="App">
    <DocumentTitle title="scotchy.co">
      <ToastProvider>
        <ApolloProvider client={client}>
          <Router>
            <Content />
          </Router>
        </ApolloProvider>
      </ToastProvider>
    </DocumentTitle>
  </div>
  );
}

export default App;
