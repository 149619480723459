import React, { useContext} from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import ShuffleLink from './ShuffleLink';

import { UserContext } from './UserContext';

const LoggedInLinks = () => {
    const user = useContext(UserContext);
    return (
        <Row>
            <Col id="header-links" className="col-8">
                <Link to="/bottle/new">add</Link>
            </Col>
            <Col id="header-profile" className="col-4">
                <Link to="/profile" alt="profile user.avatar" className="float-right">
                    <FontAwesomeIcon icon={['fad', user.avatar]} size="2x" />
                </Link>
            </Col>
        </Row>
    )
}

export default LoggedInLinks;