import React from 'react';
import { Row, Col } from 'react-bootstrap';

export const PrivacyPolicy = () => {
    return (
        <Row>
            <Col></Col>
            <Col>
                <center><h1>Privacy Policy</h1></center>
                <p>To get critical information about the behavior of our visitors, we use
                &nbsp;<a href="https://ackee.electerious.com" rel="noreferrer" target="_blank">Ackee</a>. This
                analytics software gives us insight about our visitors only in general, but not
                about individuals per say, as it does not track visitors and does not store any
                personal identifiable information.</p>
                <p><a href="https://docs.ackee.electerious.com/#/docs/Anonymization" rel="noreferrer" target="_blank">Go
                to their documentation</a> to find out what Ackee collects (and most
                importantly what they don't).</p>
            </Col>
            <Col></Col>
        </Row>
    );
}
