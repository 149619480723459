import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';

import { DEFAULT_BOTTLE_IMAGE } from '../../utils/constant';
import FourZeroFour from '../shared/ErrorStates';
import { UserContext } from '../shared/UserContext';

import Form from './Form';

import './index.css';

const GET_BOTTLE = gql`
    query Bottle($bottleId: String!) {
        bottle(id: $bottleId) {
            id
            brand
            price
            region
            score
            kind
            nose
            finish
            notes
            score
            taste
            privacy
            status
            image_url
            date_acquired
            user {
              name
              id
            }
        }
    }
`;

const Bottle = ({ data }) => {
  const currentUser = useContext(UserContext);
  const [editing, setEditing] = useState(false);

  const toggleEditOn = (ev) => {
    ev.preventDefault();
    setEditing(true);
  }

  const toggleEditOff = () => {
    setEditing(false);
  }

  if (data.networkStatus === 1) {
    return <div className="loading">Loading</div>;
  }

  if (data.error) {
    return <FourZeroFour />;
  }

  let editButton
  if (currentUser.id === data.bottle.user.id) {
    editButton = <span>| <a href="#edit" onClick={toggleEditOn}>edit</a></span>
  }

  if (editing) {
    return <Form cancelCallback={toggleEditOff} editBottle={data.bottle} />;
  }

  return (
    <div>
      <header className="header-image" id={"header-"+ data.bottle.kind}>
        <div id="blur">
          <Row>
            <Col xs={1} s={1} m={1} lg={1}></Col>
            <Col xs={10} s={10} m={10} lg={10}>
              <h1>{data.bottle.brand}</h1>
            </Col>
            <Col xs={1} s={1} m={1} lg={1}></Col>
          </Row>
        </div>
      </header>
      <div id="bottle-body">
        <div id="bottle-meta">
          <span className="meta-field">${data.bottle.price}</span>
          <span className="meta-dot">&middot;</span>
          <span className="meta-field">{_.capitalize(data.bottle.region)}</span>
          <span className="meta-dot">&middot;</span>
          <span className="meta-field">{_.capitalize(data.bottle.kind)}</span>
          <span className="meta-dot">&middot;</span>
          <span className="meta-field">{data.bottle.score}pts</span>
        </div>
        <Row id="bottle-main">
          <Col xs={12} s={12} m={6} lg={6} className="order-2 order-lg-1">
            <img
              src={`https://${process.env.REACT_APP_AWS_BUCKET}.s3.amazonaws.com/bottles/resized/600-jpeg/${_.isNull(data.bottle.image_url) ? DEFAULT_BOTTLE_IMAGE : data.bottle.image_url}`}
              alt={data.bottle.brand}
            />
            <div id="image-meta">
              added by <Link to={`/user/${data.bottle.user.id}`}>{data.bottle.user.name}</Link> on {data.bottle.date_acquired} {editButton}
            </div>
          </Col>
          <Col xs={12} s={12} m={6} lg={6} className="order-1 order-lg-2">
            <h4>Taste</h4>
            <p>{data.bottle.taste}</p>
            <h4>Finish</h4>
            <p>{data.bottle.finish}</p>
            <h4>Other Notes</h4>
            <p>{data.bottle.notes}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Bottle.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
};

export default graphql(GET_BOTTLE, {
  options: ({ bottleId }) => ({ variables: { bottleId } }),
})(Bottle);
