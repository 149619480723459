import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import _ from 'lodash';

import BottleCard from '../../ConnectedBottles/BottleCard';

import '../index.css';

const LOAD_USER_BOTTLES = gql`
    query{
        userBottles{
            age
            brand
            id
            imageUrl
            kind
            notes
            price
            region
            score
        }
    }
`;

class Bottles extends Component {
    render() {
        if (this.props.data.networkStatus === 1) {
            return <div className="loading">Loading</div>;
        }

        if (this.props.data.error) {
            return (
                <p>
                Error!
                {this.props.data.error.message}
                </p>
            );
        }

        let list;
            if (_.isEmpty(this.props.data.userBottles)) {
            list = <h3>Oops, we could not find anything</h3>
        } else {
            list = (
                <div id="user-card-list">
                {
                    this.props.data.userBottles.map((bottle) => (
                    <div className="user-card-wrapper" key={bottle.id}>
                        <BottleCard
                        id={bottle.id}
                        name={bottle.brand}
                        region={bottle.region}
                        kind={bottle.kind}
                        age={bottle.age}
                        price={bottle.price}
                        notes={bottle.notes}
                        image_url={bottle.imageUrl}
                        />
                    </div>
                    ))
                }
                </div>
            )
        }

        return (
            <div>
                <h3>Your Bottles</h3>
                {list}
            </div>
        );
    }
}

Bottles.propTypes = {
    data: PropTypes.shape({
        refetch: PropTypes.func.isRequired,
        networkStatus: PropTypes.number.isRequired,
        userBottles: PropTypes.array,
        error: PropTypes.object,
    }).isRequired
};

Bottles.defaultProps = {
    data: PropTypes.shape({
        refetch: () => {},
        networkStatus: null,
        userBottles: [],
    })
};

export default graphql(LOAD_USER_BOTTLES)(Bottles);