import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Input from '../shared/forms/Input';
import { Row, Col } from 'react-bootstrap';

class Search extends Component {
    render() {
        return (
            <Row>
                <Col xs={12} s={12} md={3} lg={3} />
                <Col xs={12} s={12} md={6} lg={6}>
                    <div className="search-form">
                        <form onSubmit={this.props.callback}>
                            <Input name="search" value={this.props.value} changeCallback={this.props.onChangeCallback} />
                            <button>
                                <FontAwesomeIcon icon={["fal", "search"]} />
                            </button>
                        </form>
                    </div>
                </Col>
                <Col xs={12} s={12} md={3} lg={3} >
                    <a href="/" className="reset" onClick={this.props.resetCallback}>reset</a>
                </Col>
            </Row>
        )
    }
}

Search.propTypes = {
    value: PropTypes.string,
    callback: PropTypes.func.isRequired,
    onChangeCallback: PropTypes.func.isRequired,
    resetCallback: PropTypes.func.isRequired
}

export default Search;