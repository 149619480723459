import { createContext } from "react";

export const UserContext = createContext({
  id: null,
  name: null,
  email: null,
  avatar: 'user',
  token: null,
  setId: () => {},
  setToken: () => {},
  setAvatar: () => {},
  setName: () => {},
  setEmail: () => {}
});