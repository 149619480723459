import React from 'react';
import PropTypes from 'prop-types';
import UserBottles from './UserBottles';

const UserBottlePage = ({match}) => (
    <UserBottles userId={match.params.id} />
)

UserBottlePage.propTypes = {
  match: PropTypes.objectOf(PropTypes.Object),
};

UserBottlePage.defaultProps = {
  match: {},
};

export default UserBottlePage;