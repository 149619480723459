import React from 'react';
import PropTypes from 'prop-types';
import Bottle from './Bottle';

const BottlePage = ({ match }) => (
  <Bottle bottleId={match.params.id} />
);

BottlePage.propTypes = {
  match: PropTypes.objectOf(PropTypes.Object),
};

BottlePage.defaultProps = {
  match: {},
};

export default BottlePage;
