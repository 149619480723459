import React, { useState, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { graphql } from 'react-apollo';
import { Link, Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import gql from 'graphql-tag';
import _ from 'lodash';

import Input from '../shared/forms/Input';
import SubmitButton from '../shared/forms/SubmitButton';
import { UserContext } from '../shared/UserContext';
import { AUTH_TOKEN } from '../../utils/constant';

import './index.css';

const LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password){
          token
          user {
              id
              avatar
              email
              name
          }
        }
      }
`;

const Login = ({
    loginForm
}) => {
    const currentUser = useContext(UserContext);
    const { addToast } = useToasts();
    const [authToken, setAuthToken] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const login = (event) => {
        event.preventDefault();
        const formatEmail = _.toLower(email);
        loginForm({variables: {email: formatEmail, password: password}}).then((result) => {
            const { token, user } = result.data.login;
            localStorage.setItem(AUTH_TOKEN, token);
            setAuthToken(token);
            currentUser.setId(user.id);
            currentUser.setEmail(user.email);
            currentUser.setName(user.name);
            currentUser.setAvatar(user.avatar);
            currentUser.setToken(token);
            addToast("Welcome back "+user.name, { appearance: 'success', autoDismiss: true })
        }).catch(() => {
            addToast("There was an error logging in, please check your details and try again", { appearance: 'error', autoDismiss: true })
        });
    }

    if (authToken) {
        return <Redirect to="/" />;
    }

    return (
        <div>
            <Row>
                <Col xs={1} s={1} m={3} lg={3}></Col>
                <Col xs={10} s={10} m={6} lg={6}>
                    <form id="login-form" onSubmit={login}>
                        <Input name="email" label={false} callback={(e) => setEmail(e.target.value)} />
                        <Input name="password" type="password" label={false} callback={(e) => setPassword(e.target.value)} />
                        <SubmitButton value="LOGIN" />
                    </form>
                </Col>
                <Col xs={1} s={1} m={3} lg={3}></Col>
            </Row>
            <Row className="help-link-section">
                <Col>
                    <p>
                        <Link className="help-link" to={'/register'} >Don&apos;t have an account yet?</Link> | <Link className="help-link" to={'/forgot-password'}>Forgot password?</Link>
                    </p>
                </Col>
            </Row>
        </div>
    );
}

export default graphql(LOGIN, {name: 'loginForm' })(Login);