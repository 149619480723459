import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import {
  Switch,
  Route,
  Redirect,
  useLocation
} from 'react-router-dom';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import useAckee from 'use-ackee';

import Nav from './components/shared/Nav';
import BottleIndex from './components/ConnectedBottles';
import BottlePage from './components/ConnectedBottle';
import NewBottle from './components/NewBottle';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Register from './components/Register';
import Profile from './components/ConnectedProfile';
import UserBottlePage from './components/ConnectedUserBottles';
import NoMatch from './components/NoMatch';
import { PrivacyPolicy } from './components/StaticPages';

import { AUTH_TOKEN } from './utils/constant';

import { UserContext } from './components/shared/UserContext';
import Footer from './components/shared/Footer';


const CURRENT_USER = gql `
  query{
    currentUser{
      email
      id
      name
      avatar
    }
  }
`;

const Content = () => {
    const { loading, data } = useQuery(CURRENT_USER);
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [token, setToken] = useState();
    const [avatar, setAvatar] = useState();
    const [id, setId] = useState();
    const value = {id, setId, email, setEmail, name, setName, token, setToken, avatar, setAvatar}

    const location = useLocation();

    useAckee(location.pathname, {
      server: 'https://analytics.scotchy.co',
      domainId: '605e3edb-4451-434e-ba0f-2d0ce703ebd7'
    }, {
      ignoreLocalhost: true,
      detailed: true
    });

    useEffect(() => {
        if (!loading && data && data.currentUser) {
          setId(data.currentUser.id);
          setEmail(data.currentUser.email);
          setToken(localStorage.getItem(AUTH_TOKEN))
          setAvatar(data.currentUser.avatar);
          setName(data.currentUser.name);
        }
      },
      [data, loading]
    );

    const authToken = token;

    return (
      <UserContext.Provider value={value}>
        <Container>
            <Nav />
            <Switch>
                <Route exact path="/" component={BottleIndex} />
                <Route path="/bottles/:id" component={BottlePage} />
                <Route path="/user/:id" component={UserBottlePage}/>
                <Route
                    path="/login"
                    render={() => (
                        authToken ? (
                            <Redirect to="/" />
                        ) : (
                            <Login />
                        )
                    )}
                />
                <Route
                    path="/forgot-password"
                    render={() => (
                        authToken ? (
                            <Redirect to="/" />
                        ) : (
                            <ForgotPassword />
                        )
                    )}
                />
                <Route
                    path="/reset-password"
                    render={() => (
                        authToken ? (
                            <Redirect to="/" />
                        ) : (
                            <ResetPassword />
                        )
                    )}
                />
                <Route
                    path="/register"
                    render={() => (
                    authToken ? (
                        <Redirect to="/" />
                    ) : (
                        <Register />
                    )
                    )}
                />
                <Route
                  path="/bottle/new"
                  render={() => (
                    authToken ? (
                      <NewBottle />
                    ) : (
                      <Redirect to="/login" />
                    )
                  )}
                />
                <Route
                  path="/profile"
                  render={() => (
                    authToken ? (
                      <Profile />
                    ) : (
                      <Redirect to="/login" />
                    )
                  )}
                />
                <Route path="/privacy" component={PrivacyPolicy} />
                <Route component={NoMatch} />
            </Switch>
            <Footer />
        </Container>
        </UserContext.Provider>
      )
    // }
}

export default Content;
