import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

const Filters = () => (
    <Row>
        <Col>
            <div id="filters-score" className="filters">
                <span className="filter-label">
                    <Link to="/">70-80</Link>
                </span>
                <span className="filter-label">
                    <Link to="/">81-90</Link>
                </span>
                <span className="filter-label">
                    <Link to="/">90+</Link>
                </span>
            </div>
        </Col>
        <Col className="col-6">
            <div className="filters">
                <span className="filter-label">
                    <Link to="/">scotch</Link>
                </span>
                <span className="filter-label">
                    <Link to="/">whiskey</Link>
                </span>
                <span className="filter-label">
                    <Link to="/">rum</Link>
                </span>
                <span className="filter-label">
                    <Link to="/">gin</Link>
                </span>
                <span className="filter-label">
                    <Link to="/">tequila</Link>
                </span>
            </div>
        </Col>
        <Col>
            <div id="filters-price" className="filters">
                <span className="filter-label">
                    <Link to="/">-$50</Link>
                </span>
                <span className="filter-label">
                    <Link to="/">$51-$100</Link>
                </span>
                <span className="filter-label">
                    <Link to="/">$100+</Link>
                </span>
            </div>
        </Col>
    </Row>
);

export default Filters;