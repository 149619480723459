import React from 'react';
import PropTypes from 'prop-types';

import './input.css';

const Select = ({
    name, callback, options, value, changeCallback
}) => (
    <div className="input-group input-group-lg scotchy-input">
        <select className="form-control" name={name} id={name} onBlur={callback} onChange={changeCallback}>
            <option>Select a style</option>
            {options.map((option) => (
                <option selected={value === option} key={option}>{option}</option>
            ))}
        </select>
    </div>
);

Select.propTypes = {
    name: PropTypes.string.isRequired,
    callback: PropTypes.func,
    changeCallback: PropTypes.func,
    options: PropTypes.object,
    value: PropTypes.string
}

Select.defaultProps = {
    name: "",
    options: [],
    callback: () => {},
    changeCallback: () => {},
    value: null
}


export default Select;
