import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { UserContext } from '../shared/UserContext';
import { AUTH_TOKEN } from '../../utils/constant';

const Sidebar = () => {
    const user = useContext(UserContext);
    const location = useLocation();

    const logout = () => {
        let logoutBool = window.confirm("Are you sure you want to logout?");
        if (logoutBool) {
            localStorage.removeItem(AUTH_TOKEN);
            user.setAvatar('user');
            user.setToken(null);
        }
    }

    return(
        <div id="profile-sidebar">
            <ul>
                <li className={location.pathname === '/profile' ? 'selected' : null}>
                    <Link to="/profile">Profile</Link>
                </li>
                <li className={location.pathname === '/profile/password' ? 'selected' : null}>
                    <Link to="/profile/password">Password</Link>
                </li>
                <li className={location.pathname === '/profile/bottles' ? 'selected' : null}>
                    <Link to="/profile/bottles">Bottles</Link>
                </li>
                <li className={location.pathname === '/profile/wants' ? 'selected' : null}>
                    <Link to="/profile/wants">Wants</Link>
                </li>
                <li>
                    <span className="logout-btn" onClick={logout}>Logout</span>
                </li>
            </ul>
        </div>
    )
};

export default Sidebar;