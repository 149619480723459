import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';
import Pagination from "react-js-pagination";
import { Row, Col } from 'react-bootstrap';

import BottleCard from './BottleCard';
import Search from './Search';
import Filters from './Filters';

const GET_BOTTLES = gql`
  query Paginated($page: String, $query: String){
    paginated(page: $page, query: $query){
      bottles {
        age
        brand
        id
        imageUrl
        kind
        notes
        price
        region
        score
      }
      distance
      pageNumber
      pageSize
      sortDirection
      sortField
      totalEntries
      totalPages
    }
  }
`;


class BottleList extends Component {
  state = {
    searchValue: ''
  }

  searchBottles = (event) => {
    event.preventDefault();
    this.props.data.refetch({query: this.state.searchValue});
  }

  resetSearch = (event) => {
    event.preventDefault();
    this.setState({searchValue: ''})
    this.props.data.refetch({query: null});
  }

  setSearch = (event) => {
    this.setState({searchValue: event.target.value})
  }

  paginateBottles = (pageNum) => {
    this.props.data.refetch({page: pageNum});
  }

  render() {
    if (this.props.data.networkStatus === 1) {
      return <div className="loading">Loading</div>;
    }

    if (this.props.data.error) {
      return (
        <p>
          Error!
          {this.props.data.error.message}
        </p>
      );
    }

    let list;
    if (_.isEmpty(this.props.data.paginated.bottles)) {
      list = <h3>Oops, we could not find anything</h3>
    } else {
      list = (
        <div id="card-list">
          {
            this.props.data.paginated.bottles.map((bottle) => (
              <div className="card-wrapper" key={bottle.id}>
                <BottleCard
                  id={bottle.id}
                  name={bottle.brand}
                  region={bottle.region}
                  kind={bottle.kind}
                  age={bottle.age}
                  price={bottle.price}
                  notes={bottle.notes}
                  image_url={bottle.imageUrl}
                />
              </div>
            ))
          }
        </div>
      )
    }

    return (
      <div>
        <Search value={this.state.searchValue} onChangeCallback={this.setSearch} callback={this.searchBottles} resetCallback={this.resetSearch} />
        {this.props.showFilters ? <Filters /> : null }
        <Row>
          {list}
        </Row>
        <Row>
          <Col xs={12} s={12} md={4} lg={4} />
          <Col xs={12} s={12} md={4} lg={4} id="pagination" className="col-4">
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={this.props.data.paginated.pageNumber}
              itemsCountPerPage={this.props.data.paginated.pageSize}
              totalItemsCount={this.props.data.paginated.totalEntries}
              pageRangeDisplayed={5}
              onChange={this.paginateBottles.bind(this)}
            />
          </Col>
          <Col xs={12} s={12} md={4} lg={4} />
        </Row>
      </div>
    );
  }
}

BottleList.propTypes = {
  data: PropTypes.shape({
    refetch: PropTypes.func.isRequired,
    networkStatus: PropTypes.number.isRequired,
    paginated: PropTypes.object,
    error: PropTypes.object,
  }).isRequired,
  showFilters: PropTypes.bool
};

BottleList.defaultProps = {
  data: PropTypes.shape({
    refetch: () => {},
    networkStatus: null,
    paginated: {
      bottles: []
    },
  }),
  showFilters: false
};

export default graphql(GET_BOTTLES, {
  options: ({query}) => ({variables: {query}})
})(BottleList);