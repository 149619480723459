import React from 'react';
import PropTypes from 'prop-types';

import './button.css';

const SubmitButton = ({
    value
}) => (
    <button type="submit" className="submit-button" >
        {value}
    </button>
);

SubmitButton.propTypes = {
    value: PropTypes.string.isRequired
}

export default SubmitButton;