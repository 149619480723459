import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { graphql } from 'react-apollo';
import { Redirect, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import gql from 'graphql-tag';
import _ from 'lodash';

import Input from '../shared/forms/Input';
import SubmitButton from '../shared/forms/SubmitButton';

import './index.css';

const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($email: String!) {
        forgotPassword(email: $email){
          id
        }
      }
`;

const ForgotPassword = ({forgotPasswordForm}) => {
    const { addToast } = useToasts();
    const [email, setEmail] = useState();
    const [formSent, setFormSent] = useState(false);

    const login = (event) => {
        event.preventDefault();
        const formatEmail = _.toLower(email);
        forgotPasswordForm({variables: {email: formatEmail}}).then(
            () => {
                addToast('Check your inbox for reset instructions', { appearance: 'success', autoDismiss: true })
                setFormSent(true);
            }
        ).catch(() => {
            addToast("Sorry there was an error, please try again", { appearance: 'error', autoDismiss: true })
        });
    }

    const setInputValue = (event) => {
        setEmail(event.target.value);
    }

    if (formSent) {
        return <Redirect to="/" />;
    }

    return (
        <div>
            <Row>
                <Col xs={1} s={1} m={3} lg={3}></Col>
                <Col xs={10} s={10} m={6} lg={6}>
                    <form id="login-form" onSubmit={login}>
                        <Input name="email" label={false} callback={setInputValue} />
                        <SubmitButton value="SEND RESET INSTRUCTIONS" />
                    </form>
                </Col>
                <Col xs={1} s={1} m={3} lg={3}></Col>
            </Row>
            <Row className="help-link-section">
                <Col>
                    <p>
                        <Link className="help-link" to={'/register'} >Don&apos;t have an account yet?</Link>
                    </p>
                </Col>
            </Row>
        </div>
    );
}

export default graphql(FORGOT_PASSWORD, {name: 'forgotPasswordForm' })(ForgotPassword);