import React, { useState } from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { Row, Col } from 'react-bootstrap';

import Select from '../shared/forms/Select';
import Input from '../shared/forms/Input';
import Textarea from '../shared/forms/Textarea';

const EDIT_BOTTLE = gql`
mutation EditBottle($id: String!, $age: Int, $brand: String, $finish: String, $kind: String, $nose: String, $notes: String, $price: Int, $dateAcquired: String, $region: String, $score: Int, $taste: String, $imageUrl: String){
    editBottle(id: $id, age: $age, brand: $brand, finish: $finish, kind: $kind, nose: $nose, notes: $notes, price: $price, region: $region, score: $score, date_acquired: $dateAcquired, taste: $taste, image_url: $imageUrl){
        id
        brand
        price
        region
        score
        kind
        nose
        finish
        notes
        score
        taste
        privacy
        status
        image_url
        date_acquired
        user {
            name
            id
        }
    }
    }
`;

const Form = ({
    saveBottle, editBottle, cancelCallback
}) => {
    const [bottle, setBottle] = useState(editBottle);

    const saveCallback = async (event) => {
        event.preventDefault();
        await saveBottle({ variables: bottle}).then(() => {
            cancelCallback()
        }).catch((err) => {
            console.log(err);
        })
    }

    const updateField = e => {
        let value = e.target.value;
        let saveValue;
        if (e.target.type === 'number') {
            saveValue = parseInt(value);
        } else {
            saveValue = value;
        }
        setBottle({
          ...bottle,
          [e.target.name]: saveValue
        });
      };

    return (
        <Row>
        <Col xs={1} s={1} md={2} lg={2}></Col>
        <Col xs={10} s={10} md={8} lg={8}>
        <form onSubmit={saveCallback}>
            <h2>Editing: <span className="edit-title">{bottle.brand}</span></h2>
            <Input name="brand" value={bottle.brand} changeCallback={updateField} />
            <Input name="price" type="number" value={bottle.price} changeCallback={updateField} />
            <Input name="score" type="number" value={bottle.score} changeCallback={updateField} />
            <Input name="region" value={bottle.region} changeCallback={updateField} />
            <Select
                name="kind"
                value={bottle.kind}
                changeCallback={updateField}
                options={['Scotch', 'Whiskey', 'Bourbon', 'Gin', 'Rum', 'Tequila', 'Mezcal', 'Cognac', 'Brandy', 'Vodka', 'Other']}
            />
            <Textarea name="nose" placeholder="nose" changeCallback={updateField} >
                {bottle.nose}
            </Textarea>
            <Textarea name="taste" placeholder="taste" changeCallback={updateField} >
                {bottle.taste}
            </Textarea>
            <Textarea name="finish" placeholder="finish" changeCallback={updateField} >
                {bottle.finish}
            </Textarea>
            <Textarea name="notes" placeholder="notes" changeCallback={updateField} >
                {bottle.notes}
            </Textarea>
            <button type="submit">Save</button>
            <a href="#done" onClick={cancelCallback}>Cancel</a>
        </form>
        </Col>
        <Col xs={1} s={1} md={2} lg={2}></Col>
        </Row>
    );
}

export default graphql(EDIT_BOTTLE, {name: 'saveBottle' })(Form);