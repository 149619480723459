import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';

import Input from '../../shared/forms/Input';
import SubmitButton from '../../shared/forms/SubmitButton';
import { UserContext } from '../../shared/UserContext';

const ProfileForm = ({submitCallback}) => {
    // Retrieve context data
  const user = useContext(UserContext);

  return (
    <form onSubmit={submitCallback}>
        <div className="input-group input-group-lg scotchy-input">
            <Row>
                <Col xs={1} s={1} m={3} lg={3}>
                    <span id="current-avatar">
                        <FontAwesomeIcon icon={['fad', user.avatar]} size="8x" />
                    </span>
                </Col>
                <Col xs={12} s={12} m={9} lg={9}>
                    <div id="avatar-options">
                        <p>Choose your avatar</p>
                        <span data-icon="user-crown" className={user.avatar === 'user-crown' ? `selected avatar-option` :`avatar-option`} onClick={() => user.setAvatar('user-crown')}>
                            <FontAwesomeIcon icon={['fad', 'user-crown']} size="3x" />
                        </span>
                        <span data-icon="user-visor" className={user.avatar === 'user-visor' ? `selected avatar-option` :`avatar-option`} onClick={e => user.setAvatar('user-visor')}>
                            <FontAwesomeIcon icon={['fad', 'user-visor']} size="3x" />
                        </span>
                        <span data-icon="user-ninja" className={user.avatar === 'user-ninja' ? `selected avatar-option` :`avatar-option`} onClick={e => user.setAvatar('user-ninja')}>
                            <FontAwesomeIcon icon={['fad', 'user-ninja']} size="3x" />
                        </span>
                        <span data-icon="user-cowboy" className={user.avatar === 'user-cowboy' ? `selected avatar-option` :`avatar-option`} onClick={e => user.setAvatar('user-cowboy')}>
                            <FontAwesomeIcon icon={['fad', 'user-cowboy']} size="3x" />
                        </span>
                        <span data-icon="user-astronaut" className={user.avatar === 'user-astronaut' ? `selected avatar-option` :`avatar-option`} onClick={e => user.setAvatar('user-astronaut')}>
                            <FontAwesomeIcon icon={['fad', 'user-astronaut']} size="3x" />
                        </span>
                        <span data-icon="user-robot" className={user.avatar === 'user-robot' ? `selected avatar-option` :`avatar-option`} onClick={e => user.setAvatar('user-robot')}>
                            <FontAwesomeIcon icon={['fad', 'user-robot']} size="3x" />
                        </span>
                        <span data-icon="user-secret" className={user.avatar === 'user-secret' ? `selected avatar-option` :`avatar-option`} onClick={e => user.setAvatar('user-secret')}>
                            <FontAwesomeIcon icon={['fad', 'user-secret']} size="3x" />
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
        <Input name="name" placeholder={user.name} callback={e => user.setName(e.target.value)} />
        <Input name="email" placeholder={user.email} callback={e => user.setEmail(e.target.value)} />
        <SubmitButton value="SAVE" />
    </form>
  );
};

export default ProfileForm;