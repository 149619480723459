import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import {flowRight as compose} from 'lodash';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { Redirect, withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { DEFAULT_BOTTLE_IMAGE } from '../../utils/constant';
import Input from '../shared/forms/Input';
import InputPrepended from '../shared/forms/InputPrepended';
import Textarea from '../shared/forms/Textarea';
import Select from '../shared/forms/Select';
import SubmitButton from '../shared/forms/SubmitButton';


const GET_PRESIGNED_URL = gql`
    mutation S3Url($fileName: String!, $fileType: String!) {
        s3Url(name: $fileName, type: $fileType){
            url
            filename
        }
    }
`;

const CREATE_BOTTLE = gql`
mutation Bottle($age: String, $brand: String, $finish: String, $kind: String, $nose: String, $notes: String, $price: String, $dateAcquired: String, $region: String, $score: String, $taste: String, $imageUrl: String){
    bottle(age: $age, brand: $brand, finish: $finish, kind: $kind, nose: $nose, notes: $notes, price: $price, region: $region, score: $score, date_acquired: $dateAcquired, taste: $taste, image_url: $imageUrl){
        id
    }
}
`;

class BottleForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            isUploadingImage: false,
            hasImage: false,
            isDoneSaving: false,
            bottleId: null,
            bottle: {
                imageUrl: DEFAULT_BOTTLE_IMAGE,
                brand: null,
                age: null,
                dateAcquired: null,
                king: null,
                region: null,
                price: null,
                score: null,
                nose: null,
                taste: null,
                finish: null,
                notes: null
            }
        }
    }

    handleDrop = async (files) => {
        var self = this;
        if (files.length > 1) {
            alert("You can only add one photo");
            return;
        }
        const file = files[0];

        this.setState({isUploadingImage: true});

        await this.props.signedUrlQuery({ variables: {fileName: file.name, fileType: file.type}})
            .then(function (result) {
                var instance = axios.create();
                var options = {
                    headers: {
                      'Content-Type': file.type
                    }
                  };
                var fileName = result.data.s3Url.filename;
                instance.put(result.data.s3Url.url, file, options)
                    .then(function () {
                        let bottle = {...self.state.bottle};
                        bottle.imageUrl = fileName

                        self.setState({bottle}, () => {
                            self.setState({isUploadingImage: false, hasImage: true});
                        })
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            }).catch(function (err) {
                console.log(err);
            });
    }

    onInputChange = (event) => {
        let bottle = {...this.state.bottle};
        bottle[event.target.name] = event.target.value

        this.setState({bottle})
    }

    onPickerChange = (value) => {
        let bottle = {...this.state.bottle};
        bottle['dateAcquired'] = value

        this.setState({bottle})
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        var self = this;
        await this.props.createBottle({ variables: this.state.bottle}).then((resp) => {
            let bottleId = resp.data.bottle.id
            self.setState({
                bottleId: bottleId,
                isDoneSaving: true
            })
        }).catch((err) => {
            console.log(err);
        })

    }

    render() {
        if (this.state.isDoneSaving === true) {
            return <Redirect to={`/bottles/`+parseInt(this.state.bottleId)} />
        }

        return(
            <form onSubmit={this.handleFormSubmit}>
                <div>
                <Row>
                    <Col>
                        <h1 className="new-bottle-header">what are you drinking today?</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 col-lg-6 order-2 order-lg-1">
                        <Row>
                            <Col>
                                <Input name="brand" callback={this.onInputChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    name="kind"
                                    callback={this.onInputChange}
                                    options={['Scotch', 'Whiskey', 'Bourbon', 'Gin', 'Rum', 'Tequila', 'Mezcal', 'Cognac', 'Brandy', 'Vodka', 'Other']}
                                />
                            </Col>
                            <Col>
                                <Input name="region" callback={this.onInputChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputPrepended prependIcon={['fal', 'dollar-sign']} name="price" callback={this.onInputChange} />
                            </Col>
                            <Col>
                                <InputPrepended  prependIcon={['fal', 'star']} name="score" callback={this.onInputChange} />
                            </Col>
                            <Col>
                                <InputPrepended prependIcon={['fal', 'hourglass-half']} name="age" callback={this.onInputChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                    <DatePicker
                                        selected={this.state.bottle.dateAcquired}
                                        onChange={this.onPickerChange}
                                        className="form-control"
                                        dateFormat="yyyy/MM/dd"
                                        customInput={<InputPrepended prependIcon={['fal', 'calendar-alt']} name="age" />}
                                    />
                            </Col>
                        </Row>
                        <Textarea name="nose" placeholder="nose" callback={this.onInputChange} />
                        <Textarea name="taste" placeholder="taste" callback={this.onInputChange} />
                        <Textarea name="finish" placeholder="finish" callback={this.onInputChange} />
                        <Textarea name="notes" placeholder="other notes" callback={this.onInputChange} />
                        <SubmitButton value="ADD BOTTLE" />
                    </Col>
                    <Col className="col-lg-6 order-1 order-lg-2">
                        <div className="form-group" id="image-upload">
                            <Dropzone onDrop={this.handleDrop}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()} id="image-upload-inner">
                                            <input className="form-control-file" {...getInputProps()} />
                                            <div className="loader-outer">
                                                <div className={this.state.isUploadingImage ? 'loader img-container' : 'img-container'}>
                                                    <img className={this.state.hasImage ? '' : 'default-image' } alt="bottle" src={`https://${process.env.REACT_APP_AWS_BUCKET}.s3.amazonaws.com/bottles/`+this.state.bottle.imageUrl} />
                                                    <p className={this.state.hasImage ? 'invisible non-mobile' : 'non-mobile'}>Drag &apos;n&apos; drop your image here, or click to select an image</p>
                                                    <p className={this.state.hasImage ? 'invisible mobile' : 'mobile'}>Click to select an image</p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                    </Col>
                </Row>
                </div>
            </form>
        );
    }
}

BottleForm.propTypes = {
    createBottle: PropTypes.func.isRequired,
    signedUrlQuery: PropTypes.func.isRequired
}



export default compose(
    graphql(CREATE_BOTTLE, {name: 'createBottle' }),
    graphql(GET_PRESIGNED_URL, { name: 'signedUrlQuery' })
)(withRouter(BottleForm));