import React, { useState, useContext } from 'react'
import { useToasts } from 'react-toast-notifications';
import { graphql } from 'react-apollo';
import { Link, Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import gql from 'graphql-tag';
import { validate } from 'email-validator';

import Input from '../shared/forms/Input';
import SubmitButton from '../shared/forms/SubmitButton';
import { UserContext } from '../shared/UserContext';
import { AUTH_TOKEN } from '../../utils/constant';

import './index.css';

const REGISTER = gql`
    mutation Register($name: String!, $email: String!, $password: String!) {
        register(name: $name, email: $email, password: $password){
          token
          user {
              id
              name
              avatar
              email
          }
        }
      }
`;

const Register = ({
    registerForm
}) => {
    const currentUser = useContext(UserContext);
    const { addToast } = useToasts();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [name, setName] = useState();
    const [success, setSuccess] = useState(false);

    const login = (event) => {
        event.preventDefault();
        if (!validate(email)) {
            addToast("Email appears to be invalid", { appearance: 'error', autoDismiss: true });
            return false;
        }
        registerForm({variables: {name: name, email: email, password: password}}).then((result) => {
            const { token, user } = result.data.register;
            localStorage.setItem(AUTH_TOKEN, token);
            setSuccess(true);
            currentUser.setId(user.id);
            currentUser.setName(user.name)
            currentUser.setToken(token)
            currentUser.setAvatar(user.avatar)
            currentUser.setEmail(user.email)
            addToast("Welcome "+user.name, { appearance: 'success', autoDismiss: true })
        }).catch(() => {
            addToast("There was an error logging in, please check your details and try again", { appearance: 'error', autoDismiss: true })
        });
    }

    if (success) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <Row>
                <Col xs={1} s={1} m={3} lg={3}></Col>
                <Col xs={10} s={10} m={6} lg={6}>
                    <form id="login-form" onSubmit={login}>
                        <Input name="name" callback={(e) => setName(e.target.value)} />
                        <Input name="email" callback={(e) => setEmail(e.target.value)} />
                        <Input name="password" type="password" callback={(e) => setPassword(e.target.value)} />
                        <SubmitButton value="CREATE ACCOUNT" />
                    </form>
                </Col>
                <Col xs={1} s={1} m={3} lg={3}></Col>
            </Row>
            <Row className="help-link-section">
                <Col></Col>
                <Col>
                    <p>
                        <Link to={'/login'} className="help-link">Already have an account?</Link>
                    </p>
                </Col>
                <Col></Col>
            </Row>
        </div>
    );
}

export default graphql(REGISTER, {name: 'registerForm' })(Register);