import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';

import MenuLinks from './MenuLinks';
import {UserContext} from './UserContext';
import './index.css';

const Nav = () => {
    const user = useContext(UserContext);
    return (
        <Navbar className="justify-content-between">
            <Navbar.Brand className="logo" id="logo">
                <Link to="/">scotchy<span className="pink">.</span>co</Link>
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
                <MenuLinks authToken={user.token} />
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Nav;