import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Input from '../../shared/forms/Input';
import SubmitButton from '../../shared/forms/SubmitButton';

const UPDATE_PASSWORD = gql`
    mutation($password: String) {
        updatePassword(password: $password) {
            name
            email
        }
    }
`;

const Password = ({
    updatePassword
}) => {
    const { addToast } = useToasts();
    const [password, setPassword] = useState();
    const [passwordConfirmation, setPasswordConfirmation] = useState();

    const validatePasswordMatch = () => {
        return password === passwordConfirmation;
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if ((_.isEmpty(password) || _.isEmpty(passwordConfirmation)) || !(validatePasswordMatch())) {
            addToast("Password do not match", { appearance: 'error', autoDismiss: true })
            return false;
        }

        updatePassword({ variables: {password: password}}).then(() => {
            setPassword('');
            setPasswordConfirmation('');
            addToast("Password updated", { appearance: 'success', autoDismiss: true })
        }).catch(() => {
            addToast("Error, please try again", { appearance: 'error', autoDismiss: true })
        })

    }

    return(
        <div>
            <form onSubmit={handleFormSubmit}>
                <Input name="password" value={password} type="password" callback={(e) => setPassword(e.target.value)} />
                <Input name="password_confirmation" value={passwordConfirmation} type="password" callback={(e) => setPasswordConfirmation(e.target.value)} />
                <SubmitButton value="SAVE" />
            </form>
        </div>
    );
}

Password.propTypes = {
    updatePassword: PropTypes.func.isRequired
}

export default graphql(UPDATE_PASSWORD, {name: 'updatePassword'})(Password);