import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LoggedInLinks from './LoggedInLinks';
import LoggedOutLinks from './LoggedOutLinks';

import './index.css';

class MenuLinks extends Component {
    render() {
        let links;

        if (this.props.authToken) {
            links = <LoggedInLinks />
        } else {
            links = <LoggedOutLinks />
        }
        return (
            links
        )
    }
}

MenuLinks.propTypes = {
    authToken: PropTypes.string.isRequired
}

MenuLinks.defaultProps = {
    authToken: null
}

export default MenuLinks;
