import React from 'react';

const FourZeroFour = () => (
  <section id="main-content">
    <h1>Oops!</h1>
    <h3>We still, haven&apos;t found, what your looking for...</h3>
  </section>
);

export default FourZeroFour;
