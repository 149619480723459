import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './textarea.css';

const Textarea = ({
    name, placeholder, callback, changeCallback
}) => (
    <div className="input-group input-group-lg scotchy-input">
        <textarea name={name} className="form-control" id={name} placeholder={_.isNull(placeholder) ? name : placeholder} onBlur={callback} onChange={changeCallback}></textarea>
    </div>
);

Textarea.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    callback: PropTypes.func,
    changeCallback: PropTypes.func
};

Textarea.defaultProps = {
    name: "",
    placeholder: null,
    callback: () => {},
    changeCallback: () => {}
};


export default Textarea;
