import React from 'react';

import Sidebar from './Sidebar';
import MainContent from './MainContent';

import './index.css';

const ConnectedProfile = () => (
    <section id="profile-page">
        <div className="container">
            <div className="row">
                <Sidebar />
                <div id="sidebar-seperator" className="col-md-1" />
                <div className="col-md-1" />
                <MainContent />
            </div>
        </div>
    </section>
);

export default ConnectedProfile;