import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './input.css';

const Input = ({
    name, placeholder, value, callback, type, defaultValue, changeCallback
}) => (
    <div className="input-group input-group-lg scotchy-input">
        <input
            name={name}
            type={type}
            className="form-control"
            id={name} placeholder={_.isNull(placeholder) ? name : placeholder} onBlur={callback}
            onChange={changeCallback}
            value={value}
            defaultValue={defaultValue}
        />
    </div>
);

Input.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    callback: PropTypes.func,
    changeCallback: PropTypes.func,
    defaultValue: PropTypes.string
}

Input.defaultProps = {
    name: "",
    placeholder: null,
    label: false,
    type: "text",
    value: null,
    defaultValue: null,
    callback: () => {},
    changeCallback: () => {}
}


export default Input;
