import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './index.css';

const Footer = () => {

    return(
        <div id="footer">
            <div className="clearfix"></div>
            <Row>
                <Col>
                    <a className="bmc-button" rel="noopener noreferrer" target="_blank" href="https://www.buymeacoffee.com/joshuaswilcox">
                        <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy me a Beer" />
                        <span className="bmc-span">Buy me a coffee</span>
                    </a>
                    <div className="clearfix"></div>
                    <p className="footer-text">&copy; 2020 scotchy.co | <Link to="/privacy">Privacy Policy</Link></p>
                </Col>
            </Row>
        </div>
    )
}

export default Footer;