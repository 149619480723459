import React from 'react';

import BottleForm from './BottleForm';

import './index.css';

const NewBottle = () => {
    return (
        <BottleForm />
    )
}

export default NewBottle;
