import React, { useState, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { graphql } from 'react-apollo';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import QueryString from 'query-string';
import { AUTH_TOKEN } from '../../utils/constant';

import Input from '../shared/forms/Input';
import SubmitButton from '../shared/forms/SubmitButton';
import { UserContext } from '../shared/UserContext';

import './index.css';

const RESET_PASSWORD = gql`
    mutation ResetPassword($password: String!, $token: String!) {
        resetPassword(password: $password, token: $token){
          token
          user {
              id
              avatar
              name
              email
          }
        }
      }
`;

const ResetPassword = ({
    resetPasswordForm
}) => {
    const currentUser = useContext(UserContext);
    const { addToast } = useToasts();
    const [success, setSuccess] = useState(false);
    const [resetToken] = useState(QueryString.parse(window.location.search).reset_token);
    const [password, setPassword] = useState();


    const login = (event) => {
        event.preventDefault();
        resetPasswordForm({variables: {password: password, token: resetToken}}).then((result) => {
            const { token, user } = result.data.resetPassword;
            localStorage.setItem(AUTH_TOKEN, token);
            addToast("Password change, you have been logged in", { appearance: 'success', autoDismiss: true })
            currentUser.setId(user.id);
            currentUser.setAvatar(user.avatar);
            currentUser.setName(user.name);
            currentUser.setToken(token);
            currentUser.setEmail(user.email);
            setSuccess(true);
        }).catch(() => {
            addToast("We are sorry there was an error, please try again", { appearance: 'error', autoDismiss: true })
        });
    }

    const setInputValue = (event) => {
        setPassword(event.target.value);
    }

    if (success) {
        return <Redirect to="/" />;
    }

    return (
        <div>
            <Row>
                <Col xs={1} s={1} m={3} lg={3}></Col>
                <Col xs={10} s={10} m={6} lg={6}>
                    <form id="login-form" onSubmit={login}>
                        <Input name="password" type="password" label={false} callback={setInputValue} />
                        <SubmitButton value="RESET PASSWORD" />
                    </form>
                </Col>
                <Col xs={1} s={1} m={3} lg={3}></Col>
            </Row>
            <Row className="help-link-section">
                <Col>
                    <p>
                        <Link className="help-link" to={'/register'} >Don't have an account yet?</Link>
                    </p>
                </Col>
            </Row>
        </div>
    );
}

export default graphql(RESET_PASSWORD, {name: 'resetPasswordForm' })(ResetPassword);