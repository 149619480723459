import React from 'react';

import BottleList from './BottleList';

const BottlesIndex = () => (
  <div>
    <BottleList />
  </div>
);
export default BottlesIndex;
