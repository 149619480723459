import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import './input.css';

const InputPrepended = ({
    name, placeholder, prependIcon, callback, type
}) => (
    <div className="input-group input-group-lg scotchy-input">
        <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-lg">
                <FontAwesomeIcon icon={prependIcon} />
            </span>
        </div>
        <input name={name} type={type} className="form-control" id={name} placeholder={_.isNull(placeholder) ? name : placeholder} onBlur={callback} />
    </div>
);

InputPrepended.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    prependIcon: PropTypes.object,
    type: PropTypes.string,
    value: PropTypes.string,
    callback: PropTypes.func
}

InputPrepended.defaultProps = {
    name: "",
    placeholder: null,
    prependIcon: [],
    type: "text",
    value: "",
    callback: () => {}
}


export default InputPrepended;
