import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import TextTruncate from 'react-text-truncate';
import TruncateString from 'react-truncate-string'

import { DEFAULT_BOTTLE_IMAGE } from '../../utils/constant';

import './index.css';

const BottleCard = ({
  id, name, kind, region, age, price, notes, image_url,
}) => (
  <div className="bottle-card">
    <Row>
      <Col className="bottle-card-image col-4">
        <Link to={`/bottles/${id}`}>
          <img src={`https://${process.env.REACT_APP_AWS_BUCKET}.s3.amazonaws.com/bottles/resized/cropped-to-square/${_.isNull(image_url) ? DEFAULT_BOTTLE_IMAGE : image_url}`} alt={name} width="100%" />
        </Link>
      </Col>
      <Col className="bottle-card-data col-8">
        <Link to={`/bottles/${id}`}>
          <h3 className="bottle-card-data-title">
            <TruncateString text={name} />
          </h3>
        </Link>
        <div className="bottle-card-data-subtitle">
          {kind}
          <span className="bottle-card-data-subtitle-dot">&middot;</span>
          {region}
          <span className="bottle-card-data-subtitle-dot">&middot;</span>
          {age}
          {' '}
          years
          <span className="bottle-card-data-subtitle-dot">&middot;</span>
          ${price}
        </div>
        <div className="bottle-card-data-body" >
          <TextTruncate
              line={3}
              element="span"
              truncateText="…"
              text={notes}
              textTruncateChild={<Link to={`/bottles/${id}`}>more</Link>}
          />
        </div>
      </Col>
    </Row>
  </div>
);

BottleCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  age: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  notes: PropTypes.string.isRequired,
  image_url: PropTypes.string.isRequired,
};

export default BottleCard;
