import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import BottleCard from '../ConnectedBottles/BottleCard';

import './index.css';

const USER_BOTTLES = gql`
  query UserBottle($userId: String!){
    usersBottles(userId: $userId){
      age
      brand
      dateAcquired
      deleted
      finish
      id
      imageUrl
      kind
      nose
      notes
      price
      privacy
      region
      score
      status
      taste
    }
    user(id: $userId) {
      name
      avatar
    }
  }
`;

class UserBottles extends Component {
    render() {
        if (this.props.data.networkStatus === 1) {
            return <div className="loading">Loading</div>;
        }

        if (this.props.data.error) {
            return (
                <p>
                Error!
                {this.props.data.error.message}
                </p>
            );
        }

        return (
            <div>
                <div id="users-bottle-list">
                    <h2 id="users-bottle-list-title">Bottle List for {this.props.data.user.name}</h2>
                    <div id="card-list">
                    {
                        this.props.data.usersBottles.map((bottle) => (
                        <div className="card-wrapper" key={bottle.id}>
                            <BottleCard
                            id={bottle.id}
                            name={bottle.brand}
                            region={bottle.region}
                            kind={bottle.kind}
                            age={bottle.age}
                            price={bottle.price}
                            notes={bottle.notes}
                            image_url={bottle.imageUrl}
                            />
                        </div>
                        ))
                    }
                    </div>
                </div>
            </div>
        )
    }
}

UserBottles.propTypes = {
    data: PropTypes.shape({
        refetch: PropTypes.func.isRequired,
        networkStatus: PropTypes.number.isRequired,
        usersBottles: PropTypes.array,
        user: PropTypes.object,
        error: PropTypes.object,
    }).isRequired
}

export default graphql(USER_BOTTLES, {
    options: ({ userId }) => ({ variables: { userId } }),
  })(UserBottles);